import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useSWR from "swr";

import DescriptionHolder from "../../components/DescriptionHolder";
import Loader from "../../components/Loader";
import notify from "../../components/Notification/helper";
import PageHeader from "../../components/PageHeader";

import RetailerContext from "../../context";

import { getTranslation } from "../../utils/translate";
import { getBeefProductOverride } from '../../utils/overrides';
import { fetcher } from "../../utils/fetcher";
import constants from "../../utils/constants";

import { useUpdateDocumentMeta } from "../../hooks/useUpdateDocumentMeta";

import styles from './index.module.scss';
import AccordionSection from "../../components/AccordionSection";
import Collection from "../../components/Collection";
import BeefCuts from "../../components/Collection/BeefCuts";

function BeefPrimal() {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);

  const { beefProductId } = useParams();

  const fields = [
    'beef_product_brandings.beef_product_branding_id.brand_color',
    'beef_product_brandings.beef_product_branding_id.id',
    'beef_product_brandings.beef_product_branding_id.image',
    'beef_product_brandings.beef_product_branding_id.logo',
    'beef_product_brandings.beef_product_branding_id.translations.description',
    'beef_product_brandings.beef_product_branding_id.translations.id',
    'beef_product_brandings.beef_product_branding_id.translations.languages_code',
    'beef_product_brandings.beef_product_branding_id.translations.name',
    'beef_product_brandings.beef_product_branding_id.retailers.retailer_id',
    'beef_product_brandings.beef_product_branding_id.retailer',
    'beef_product_brandings.beef_product_branding_id.status',
    'beef_product_overrides.image',
    'beef_product_overrides.retailer',
    'beef_product_overrides.status',
    'beef_product_overrides.svg_icon',
    'beef_product_overrides.translations.description',
    'beef_product_overrides.translations.languages_code',
    'beef_product_overrides.translations.name',
    'collections.*.*',
    'children.*.*',
    'children.beef_product_overrides.retailer',
    'children.beef_product_overrides.status',
    'children.beef_product_overrides.translations.description',
    'children.beef_product_overrides.translations.languages_code',
    'children.beef_product_overrides.translations.name',
    'children.recipes.recipe_id.retailers',
    'cooking_methods.id',
    'cooking_methods.cooking_method.translations.id',
    'cooking_methods.cooking_method.translations.languages_code',
    'cooking_methods.cooking_method.translations.Method',
    'cooking_methods.Recommended',
    'cooking_methods.translations.id',
    'cooking_methods.translations.languages_code',
    'cooking_methods.translations.Directions',
    'id',
    'image',
    'Nutrients.calories',
    'Nutrients.id',
    'Nutrients.translations.id',
    'Nutrients.translations.description',
    'Nutrients.translations.languages_code',
    'Nutrients.translations.macronutrients',
    'Nutrients.translations.micronutrients',
    'Nutrients.translations.portion_size',
    'svg_icon',
    'translations.id',
    'translations.description',
    'translations.languages_id',
    'translations.name',
    'translations.specifications',
    'translations.tips',
    'translations.vimeo',
    'translations.youtube',
  ];

  const { data:beefProduct, error, isLoading } = useSWR({
        url: `beef_product/${beefProductId}`,
        fields,
    }, fetcher);
  
  useUpdateDocumentMeta(beefProduct?.name);

  if (!!beefProduct) {
    const translatedBeef = getTranslation(
      beefProduct,
      {
        key: 'languages_id',
        code: language.code
      }
    );
        

    // handle beef name & description overrides
    beefProduct.name = getTranslation(
      getBeefProductOverride(beefProduct, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).name ||
      getTranslation(
        beefProduct,
        {
          key: 'languages_id',
          code: language.code
        }).name;

    beefProduct.description = getTranslation(
      getBeefProductOverride(beefProduct, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).description ||
      getTranslation(
        beefProduct,
        {
          key: 'languages_id',
          code: language.code
        }).description;

    // handle beef image & icon overrides
    beefProduct.image = getBeefProductOverride(beefProduct, retailer, language).image || beefProduct.image;
    beefProduct.svg_icon = getBeefProductOverride(beefProduct, retailer, language).svg_icon || beefProduct.svg_icon;

    // specifications foldable
    beefProduct.specifications = getTranslation(
      beefProduct,
      {
        key: 'languages_id',
        code: language.code
      }
    ).specifications;

    // build videos data
    beefProduct.videos = [
    ...(
      translatedBeef.vimeo?.map(vimeo => {
        const id = vimeo.vimeo_id.replace('https://vimeo.com/', '');

        return {
          id,
          sourceUrl: `${constants.VIMEO_PLAYER_URL}/${id}`,
          videoType: 'vimeo',
        }
      }) || []
    ),
    ...(
      translatedBeef.youtube?.map(youtube => ({
        id: youtube.youtube_id,
        sourceUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
        videoType: 'youtube',
      })) || []
    )
  ];
  }

  if (isLoading) {
    return <Loader />
  }
  
  return (
    <Box
      className="beef-product"
    > 
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: beefProduct?.name,
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
            mt: 5
        }}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
            <Typography
                className='search-message'
                sx={{
                    color: '#000',
                    fontWeight: 300,
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
                component="div"
            >
                <DescriptionHolder content={beefProduct.description} />
            </Typography>
        </Box>
        
        { !!beefProduct?.image &&
          <Stack
              alignItems={'center'}
              justifyContent={'center'}
          >
              <Grid
                  className='beef-product_img_container'
                  item
                  sm={12}
                  sx={{
                    mb: 3,
                    textAlign: 'center'
                  }}
              >
                <img
                    className={styles.beef_product_img}
                    src={`${process.env.REACT_APP_API_URL}/assets/${beefProduct.image}?key=page-hero-image-contain`}
                    alt={beefProduct?.name} 
                  />
              </Grid>
          </Stack>
        }
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
          mb: 3
        }}
      >

        <BeefCuts
          beefCuts={beefProduct.children || []}
        />

        {
          beefProduct.collections?.map(collection => (
            <Box
              key={collection.id}
            >
              <AccordionSection
                title={getTranslation(collection, { key: 'languages_code', code: language.code}).title}
                expanded={true}
              >
                <Collection
                  id={collection.id}
                  content
                  image
                />
              </AccordionSection>
            </Box>
          ))
        }
      </Container>
    </Box>
  )
}

export default BeefPrimal