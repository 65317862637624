import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import ContestArt from '../../../shared/img/Bitmap.png';

import Wysiwyg from "../../Wysiwyg";

import styles from './styles.module.scss';
import { DialogContestFooter } from "../DialogContestFooter";
import { DialogContestTitle } from "../DialogContestTitle";
import api from "../../../api";
import { useGetShortCode } from "../../../hooks/useGetShortCode";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestSuccessDialogBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { retailer } = useContext(RetailerContext);

    const [nextAchievement, setNextAchievement] = useState(null);
    const [completedAchievementsCount, setCompletedAchievementsCount] = useState(null);

    const shortCode = useGetShortCode();

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    const handleOnClose = (close = false) => {
        let modalToOpen = nextAchievement?.modalToOpen;

        // if user has completed all achievements, then open promotion
        if (completedAchievementsCount === 3) {
            modalToOpen = 'promotion';
        }

        // if close is true, then close the modal
        if (close === true) {
            modalToOpen = 'promotion';
        }

        onClose(modalToOpen);
    };

    const fetchAchievements = async (id) => {
        try {
            // check if user has already earned achievement
            const contestJunctionItems = await api.getJunctionItems('points', {
                filter: {
                    _and: [
                        {
                            achievement: {
                                _eq: id,
                            },
                        },
                        {
                            short_code: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            return contestJunctionItems
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    // order is important here
    const orderedAchievementsList = [
        {
            id: process.env.REACT_APP_CONTEST_ACHIEVEMENT_ID,
            modalToOpen: 'contest-info',
        },
        {
            id: process.env.REACT_APP_SCAN_ACHIEVEMENT_ID,
            modalToOpen: 'watch-video-scan',
        },
        {
            id: process.env.REACT_APP_BEEF_ACHIEVEMENT_ID,
            modalToOpen: 'watch-video-beef',
        },
    ];

    useEffect(async () => {
        let nextAchievementId = null;
        let completed = 0;

        // check if each of the achievements have been completed
        for (let i = 0; i < orderedAchievementsList.length; i++) {
            const achievementItem = orderedAchievementsList[i];

            // skip if current achievement item is the same as the achievement prop
            if (achievementItem.id === process.env.REACT_APP_CONTEST_ACHIEVEMENT_ID) {
                completed += 1;
                continue;
            }

            const achievements = await fetchAchievements(achievementItem.id);

            // if achievement has not been completed, then set it as next achievement
            if (!achievements?.length) {
                // if nextAchievementId is null, then set it to the current achievementId
                if (!nextAchievementId) {
                    nextAchievementId = achievementItem;
                }
            } else {
                completed += 1;
            }
        }

        setNextAchievement(nextAchievementId);
        setCompletedAchievementsCount(completed);
    }, [open]);

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={() => handleClose('promotion')}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Box
                    sx={{
                        textAlign: 'right',
                        fontSize: '0.6rem'
                    }}
                >
                    Entries {completedAchievementsCount}/3
                </Box>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                        background: "#eeeeee",
                        mb: '0.5rem',
                    }}
                >
                    <Box
                        sx={{
                            height: {
                                xs: 'auto',
                            },
                            width: `${Math.floor((completedAchievementsCount / 3) * 100)}%`,
                            background: "#004466",
                            clear: "none",
                            zIndex: "-1",
                            position: "relative-layer",
                            textAlign: 'right',
                            color: '#ffffff',
                        }}
                    >
                        {Math.floor((completedAchievementsCount / 3) * 100)}% &nbsp;
                    </Box>
                </Box>
                
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: '1.25rem',
                        textTransform: 'uppercase',
                    }}
                >
                    <Wysiwyg
                        component={'span'}
                    >
                        {t('enterContestSuccess')}
                    </Wysiwyg>
                </Typography>

                <Typography
                    variant="h2"
                    sx={{
                        color: '#000000',
                        fontSize: '1.26rem',
                        fontWeight: "400",
                        textAlign: 'center',
                        lineHeight:"1.2rem",
                        letterSpacing: "0.00rem",
                        mt: 1,
                        mb: 0,
                        textTransform: 'uppercase',
                    }}
                >
                    {completedAchievementsCount === 1 && <Wysiwyg>{t('enterContestSuccessTitle')}</Wysiwyg>}
                    {completedAchievementsCount === 2 && <Wysiwyg>{t('enterContestSuccessTitle')}</Wysiwyg>}
                    {completedAchievementsCount === 3 && <Wysiwyg>{t('completedAllAchievementsContent')}</Wysiwyg>}
                </Typography>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                    }}
                    
                >
                    
                    <img
                        src={ContestArt}
                        alt={'Contest Art'}
                        style={{
                            height: '120px',
                            width: '100%',
                            objectFit: 'contain',
                            objectPosition: 'bottom',
                        }}
                    />
                    
                </Box>
                
                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: '0',
                                backgroundColor: '#008F01',
                                fontFamily: retailer.button_font,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 1,
                            }}
                            onClick={handleOnClose}
                        >
                            {completedAchievementsCount === 1 && t('enterContestSuccessButtonText')}
                            {completedAchievementsCount === 2 && t('enterContestSuccessButtonText')}
                            {completedAchievementsCount === 3 && t('completedAllAchievements')}
                        </Button>

                        <Box>
                            <Typography
                                sx={{
                                    color: '#1A1511',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    textAlign: 'center',
                                    textDecoration: 'underline',
                                    mb: 1,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleClose('promotion')}
                            >
                                <Wysiwyg>
                                    {t('maybeLater')}
                                </Wysiwyg>
                            </Typography>
                        </Box>

                        <DialogContestFooter
                            rules={false}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
export const DialogContestSuccess = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box className='no-print'>
            <ContestSuccessDialogBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
};