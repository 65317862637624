import userSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { fetcher } from '../utils/fetcher';
import { useGetShortCode } from './useGetShortCode';
export const useFetchPoints = () => {
    const shortCode = useGetShortCode();

    const fields = [
        '*',
    ];

    const deep = {};

    const filter = {
        _and: [
            {
                short_code: {
                    '_eq': shortCode
                }
            },
        ]
    };

    const { data: defaultData } = userSWR({
        url: 'points',
        deep,
        limit: -1,
        fields,
        filter,
        aggregate: {
            sum: 'points',
        }
    }, fetcher);

    const { data, trigger } = useSWRMutation({
        url: 'points',
        deep,
        limit: -1,
        fields,
        filter,
        aggregate: {
            sum: 'points',
        }
    }, fetcher);

    const [totalPoints] = data || defaultData || []; 

    return {
        totalPoints: totalPoints?.sum?.points || '-',
        trigger,
    };
};