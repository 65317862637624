import { useEffect, useState } from "react";

import VideoWatched from "../../VideoWatched";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import notify from "../../Notification/helper";
import api from "../../../api";

export const DialogContestRegistrationSuccess = ({ achievementId, collection, id }) => {
    const shortCode = useGetShortCode();

    const [enteredContent, setEnteredContent] = useState(null);

    const getContestJunctionItems = async () => {
        try {
            const contestJunctionItems = await api.getJunctionItems('contests_short_codes', {
                filter: {
                    _and: [
                        {
                            contests_id: {
                                _eq: process.env.REACT_APP_CONTEST_ID,
                            },
                        },
                        {
                            short_codes_id: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            if (contestJunctionItems?.length) {
                setEnteredContent(true);
            } else {
                setEnteredContent(false);
            }
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    useEffect(async () => {
        getContestJunctionItems();
    }, []);

    return (
        <>
            {
                enteredContent &&
                <VideoWatched
                    achievementId={achievementId}
                    collection={collection}
                    id={id}
                    showModalOverride={false}
                />
            }
        </>
    )
}