import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import Wysiwyg from "../../Wysiwyg";
import api from "../../../api";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import notify from "../../Notification/helper";

import styles from './styles.module.scss';
import { DialogContestFooter } from "../DialogContestFooter";
import { DialogContestTitle } from "../DialogContestTitle";
import { fetchShortCode } from "../../../utils/shortCode";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../utils/translate";
import VideoWatched from "../../VideoWatched";
import { AwardsContext } from "../../../context/AwardsContext";
import { pushToGoogleAnalytics } from "../../../utils/google-analytics";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestFormDialogBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    let shortCode = useGetShortCode();

    const { retailer } = useContext(RetailerContext);
    const { achievement } = useContext(AwardsContext);

    const { code: languageCode } = useSelector(state => state.language);

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    let terms;
    let title;
    let consent;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        terms = translatedContest?.terms || '';
        title = translatedContest?.heading || '';
        consent = translatedContest?.consent || '';
    }

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [verifyEmail, setVerifyEmail] = useState('');
    const [province, setProvince] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [oldEnough, setOldEnough] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [receiveUpdates, setReceiveUpdates] = useState(false);
    const [receiveUpdatesRetailer, setReceiveUpdatesRetailer] = useState(false);

    const [isRegistered, setIsRegistered] = useState(false);

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    const onSubmit = async () => {
        let postShortCodeDetailsError = '';
        let postEnterContestError = '';

        if (!shortCode) {
            shortCode = await fetchShortCode();
        }

        try {
            setLoading(true);

            const userDetailsPayload = {
                name,
                surname,
                email,
                mobile,
                province,
                receive_updates: receiveUpdates,
                receive_updates_retailer: receiveUpdatesRetailer,
                agreeToTerms,
            };

            const enterContestPayload = {
                contests_id: contest?.id,
                short_codes_id: shortCode,
            };

            postShortCodeDetailsError = await api.postShortCodeDetails(shortCode, userDetailsPayload);
            postEnterContestError = await api.enterContest(enterContestPayload);
            setIsRegistered(true);
            pushToGoogleAnalytics({
                event: 'formSubmit',
                type: 'contest-form',
                name: 'Gift Card Contest September 19 2024 to October 9 2024',
            });
        } catch(error) {
            setIsRegistered(false);
            error?.map(err => {
                const errorMessage = {
                    message: `${err?.message}`,
                    postShortCodeDetailsError,
                    postEnterContestError,
                    shortCode
                };

                notify('error', JSON.stringify(errorMessage));
            });
            setLoading(false);
        } finally {
        }
    }

    const readyToSubmit = () => {
        return (
            name &&
            surname &&
            email && email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) &&
            email === verifyEmail &&
            mobile && mobile.match(/^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) &&
            province &&
            receiveUpdates &&
            receiveUpdatesRetailer &&
            agreeToTerms &&
            oldEnough
        )
    };

    const getLegalAgeLabel = (province) => {
        const provincesWith18AsLegalAge = [
            'AB',
            'MB',
            'ON',
            'PE',
            'QC',
            'SK',
        ];

        if (provincesWith18AsLegalAge.includes(province)) {
            return t('legalAge18');
        } else {
            return t('legalAge18');
        }
    }

    useEffect(async () => {
        try {
            const cities = [
                'Aurora',
                'Barrie',
                'Brantford',
                'Burlington',
                'Cambridge',
                'Cornwall',
                'Guelph',
                'Hamilton',
                'Kingston',
                'Kitchener',
                'London',
                'Mississauga',
                'Newmarket',
                'Oakville',
                'Oshawa',
                'Ottawa',
                'Pickering',
                'Richmond Hill',
                'St. Catharines',
                'Toronto',
                'Waterloo',
                'Whitby',
            ]

            const formattedProvinces = cities.map(city => (
                {
                    name: city,
                }
            ));

            setProvinces(formattedProvinces);
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }, []);

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={() => handleClose('promotion')}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        my: 2,

                    }}
                >
                    <Wysiwyg>
                        {title || t('enterContestSubtitle')}
                    </Wysiwyg>
                </Typography>

                {
                    isRegistered &&
                    <VideoWatched
                        id={achievement.id}
                        achievementId={achievement.id}
                        collection={'registration'}
                    />
                }

                <Box>
                    <Typography
                        component={'div'}
                        sx={{
                            color: '#000',
                            fontWeight: 300,
                            fontSize: '0.8rem',
                            mb: 1,
                            textTransform: 'capitalize',
                        }}
                    >
                        <Wysiwyg>
                            {terms}
                        </Wysiwyg>
                    </Typography>

                    <Stack
                        spacing={1}
                        sx={{
                            my: 0,
                        }}
                    >
                        <Stack
                            sx={{
                                my: 0,
                                flexGrow: 1,
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('name').toLocaleUpperCase()} *`}
                                    value={name}
                                    onChange={ (e) => setName(e.target.value) }
                                    variant="filled"
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('surname').toLocaleUpperCase() } *`}
                                    value={surname}
                                    onChange={ (e) => setSurname(e.target.value) }
                                    variant="filled"
                                />
                            </FormControl>
                            
                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('email').toLocaleUpperCase()} *`}
                                    value={email}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="filled"
                                />

                                {
                                    !!email && !email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailError')}
                                    </Typography>
                                }
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('verifyEmail').toLocaleUpperCase()} *`}
                                    value={verifyEmail}
                                    type="email"
                                    onChange={(e) => setVerifyEmail(e.target.value)}
                                    variant="filled"
                                />

                                {
                                    !!verifyEmail && !verifyEmail.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailError')}
                                    </Typography>
                                }

                                {
                                    !!email && verifyEmail !== email &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailNotMatchError')}
                                    </Typography>
                                }
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '42px',
                                        py: 1,
                                        width: '100%'
                                    }}
                                    placeholder={`${t('mobile').toLocaleUpperCase()} *`}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    variant="filled"
                                />

                                {
                                    !!mobile && !mobile.match(
                                        /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
                                    ) &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationMobileError')}
                                    </Typography>
                                }
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <Select
                                    className="contest-input-field"
                                    value={province}
                                    onChange={(event) => setProvince(event.target.value)}
                                    displayEmpty
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                >
                                    <MenuItem value="">
                                        <Typography
                                            variant="span"
                                            sx={{
                                                color: '#adadad',
                                            }}
                                        >
                                            {`${t('province').toLocaleUpperCase()} *`}
                                        </Typography>
                                    </MenuItem>

                                    {
                                        provinces.map(province => (
                                            <MenuItem
                                                key={province.name}
                                                value={province.name}
                                            >
                                                {t(province.name, { ns: 'provinces' })}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {
                                !!province &&
                                <FormControl
                                    variant="outlined"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={oldEnough}
                                                onChange={(event) => setOldEnough(event.target.checked)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    lineHeight: '0.9rem'
                                                }}
                                            >
                                                <Wysiwyg>
                                                    {`${getLegalAgeLabel(province)}<strong>&nbsp;*</strong>`}
                                                </Wysiwyg>
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            }
                            
                            <FormControl
                                variant="outlined"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreeToTerms}
                                            onChange={(event) => setAgreeToTerms(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            <Wysiwyg>
                                            {`${t('agreeToTerms')}*`}
                                            </Wysiwyg>
                                           
                                        </Typography>
                                    }
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mt: 2
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={receiveUpdates}
                                            onChange={(event) => setReceiveUpdates(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            <Wysiwyg>
                                                {`${t('receiveUpdates')}<strong>&nbsp;*</strong>`}
                                            </Wysiwyg>
                                        </Typography>
                                    }
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 3,
                                    mt: 2
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={receiveUpdatesRetailer}
                                            onChange={(event) => setReceiveUpdatesRetailer(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            <Wysiwyg>
                                                {`${t('receiveUpdatesRetailer')}<strong>&nbsp;*</strong>`}
                                            </Wysiwyg>
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Box>

                <Typography
                    sx={{
                        color: '#000',
                        mb: 1.5,
                        fontStyle: 'italic',
                        fontSize: '0.8rem',
                        lineHeight: '0.9rem'
                    }}
                >
                    * {t('requiredFields')}
                </Typography>
                
                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: '0',
                                backgroundColor: '#008F01',
                                fontFamily: retailer.button_font,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 1,
                            }}
                            onClick={onSubmit}
                            disabled={!readyToSubmit() || loading}
                        >
                            {t('enterContest')}
                        </Button>

                        <Typography
                            component={'div'}
                            sx={{
                                color: '#000',
                                my: 1,
                                fontStyle: 'italic',
                                fontSize: '0.8rem',
                                lineHeight: '0.9rem',
                                '.wysiwyg p': {
                                    m: 0,
                                }
                            }}
                        >
                            <Wysiwyg>
                                {consent}
                            </Wysiwyg>
                        </Typography>

                        <DialogContestFooter
                            rules={true}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const DialogContestForm = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box className='no-print'>
            <ContestFormDialogBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
};