import { useEffect, useState } from "react";
import { AwardsContext } from "../context/AwardsContext";
import { pushToGoogleAnalytics } from "../utils/google-analytics";

function AwardsProvider({ children }) {
    const [achievement, setAchievement] = useState({});
    const [modalToOpen, setModalToOpen] = useState();
    const [openAwardsDialog, setOpenAwardsDialog] = useState(false);
    const [showPoints, setShowPoints] = useState(false);

    function showAwardModal(achievement, showModal=true) {
        setAchievement(achievement);

        if (!showModal) {
            showPointsAnimation();
        } else {
            setOpenAwardsDialog(true);
        }
    }

    const setModalToOpenWithItem = (modalToOpenKey, item) => {
        // TODO change this it setItem
        setAchievement(item);
        setModalToOpen(modalToOpenKey);
    };

    function hideAwardModal() {
        setAchievement({});
        setOpenAwardsDialog(false);
        showPointsAnimation();
    }

    function showPointsAnimation() {
        setShowPoints(true);
    }

    function hidePointsAnimation() {
        setShowPoints(false);
    }

    useEffect(() => {
        if (modalToOpen) {
            pushToGoogleAnalytics({
                event: 'modalOpen',
                type: modalToOpen,
                name: 'Gift Card Contest September 19 2024 to October 9 2024',
            });
        }
    }, [modalToOpen]);

    return (
        <AwardsContext.Provider
            value={{
                achievement,
                hideAwardModal,
                hidePointsAnimation,
                modalToOpen,
                openAwardsDialog,
                setAchievement,
                setModalToOpen,
                setModalToOpenWithItem,
                setOpenAwardsDialog,
                showAwardModal,
                showPoints,
                showPointsAnimation,
            }}
        >
            {children}
        </AwardsContext.Provider>
    )
}

export default AwardsProvider