import { useTranslation } from "react-i18next";

import { Grid, Stack, Typography } from "@mui/material";

import useSWR from "swr";

import MenuItems from "./MenuItems";
import BeefCuts from "./BeefCuts";
import Collections from "./Collections";
import Loader from "../Loader";
import { getTranslation } from "../../utils/translate";
import { useSelector } from "react-redux";
import Videos from "./Videos";
import Menu from "../Menu";
import { Box } from "@mui/system";
import Recipes from "./Recipes";
import Wysiwyg from "../Wysiwyg";
import AccordionSection from "../AccordionSection";

import styles from './index.module.scss';
import { bool, string } from "prop-types";
import { fetcher } from "../../utils/fetcher";
import Articles from "./Articles";

function Collection({ id, content, embedded }) {
    const language = useSelector(state => state.language);
    const { t } = useTranslation();

    const apiFieldsToFetch = [
        'id',
        ...(
            content ? ['translations.*'] : []
        ),
        'sort',
        'items.collection',
        'items.id',
        'items.item.*',
        'items.item.beef_product_overrides.id',
        'items.item.beef_product_overrides.retailer',
        'items.item.beef_product_overrides.status',
        'items.item.beef_product_overrides.translations.*',
        'items.item.category.status',
        'items.item.category.translations.*',
        'items.item.imagegallery.*',
        'items.item.layout_translations.*',
        'items.item.retailers.retailer_id',
        'items.item.retailers.sort',
        'items.item.items.*',
        'items.item.translations.summary',
        'items.item.translations.external',
        'items.item.translations.image',
        'items.item.translations.label',
        'items.item.translations.languages_code',
        'items.item.translations.languages_id',
        'items.item.translations.name',
        'items.item.translations.title',
        'items.item.translations.url',
    ];

    const { data: collectionObject, isLoading: isCollectionLoading } = useSWR({ url: `collections/${id}`, fields: apiFieldsToFetch }, fetcher);

    let translatedCollectionObject = {};
    let imageUrl = null;

    let beefCuts = [];
    let collections = [];
    let menus = [];
    let menuItems = [];
    let recipes = [];
    let videos = [];
    let articles = [];

    if (collectionObject) {
        translatedCollectionObject = getTranslation(collectionObject, { key: 'languages_code', code: language.code });

        imageUrl = translatedCollectionObject.image ? `${process.env.REACT_APP_API_URL}/assets/${translatedCollectionObject.image}?key=system-large-contain` : null;

        // TODO DRY this up
        articles = collectionObject.items
            ?.filter(junction => junction.collection === 'pages')
            ?.map(junction => junction.item);
        console.log('articles', articles);

        menus = collectionObject.items
            ?.filter(junction => junction.collection === 'menus')
            ?.map(junction => junction.item);

        menuItems = collectionObject.items
            ?.filter(junction => junction.collection === 'menu_items')
            ?.map(junction => junction.item);

        videos = collectionObject.items
            ?.filter(junction => junction.collection === 'video_library')
            ?.map(junction => junction.item);

        beefCuts = collectionObject.items
            ?.filter(junction => junction.collection === 'beef_product')
            ?.map(junction => junction.item);

        recipes = collectionObject.items
            ?.filter(junction => junction.collection === 'recipe')
            ?.map(junction => junction.item);

        collections = collectionObject.items
            ?.filter(junction => junction.collection === 'collections')
            ?.map(junction => junction.item);
    }

    if (isCollectionLoading) {
        return <Loader compact />
    }

    return (
        <Stack
            spacing={3}
        >
            {
                !!imageUrl &&
                <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Grid
                        className={styles.collection_img_container}
                        item
                        xs={12}
                    >
                        <img
                            className={styles.collection_img}
                            src={imageUrl}
                                alt={translatedCollectionObject.title}
                            style={{
                                objectFit: embedded ? 'contain' : 'cover'
                            }}
                        />
                    </Grid>
                </Stack>
            }

            {
                (!!translatedCollectionObject.content && !!content) &&
                <Typography
                    component={'div'}
                >
                    <Wysiwyg>
                        {translatedCollectionObject.content}
                    </Wysiwyg>
                </Typography>
            }

            {
                menus?.map(menu => (
                    <Box
                        sx={{
                            pb: 5
                        }}
                        key={menu.id}
                    >
                        <Menu
                            id={menu.id}
                        />
                    </Box>
                ))
            }

            {
                !!menuItems.length &&
                <MenuItems menuItems={menuItems} />
            }

            {
                !!beefCuts.length &&
                <BeefCuts beefCuts={beefCuts} />
            }

            {
                !!recipes.length &&
                <Recipes recipes={recipes} />
            }

            {
                !!collections.length &&
                <Collections collections={collections} />
            }

            {
                !!videos.length &&
                <Videos videos={videos} />
            }

            {
                !!articles.length &&
                <AccordionSection
                    title={t('articles')}
                >
                    <Articles items={articles} />
                </AccordionSection>
            }
        </Stack>
    )
}

Collection.propTypes = {
    id: string.isRequired,
    content: bool, // whether to show content or not
    embedded: bool, // whether this collection is embedded within another page or being displayed as a standalone page
}

export default Collection